import LinkRequests from "./LinkRequests";
import MyLinks from "./MyLinks";
import User from "./User";
import Post from "./Post";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import ApiService from "../../Utils/ApiService";
import routes from "../../Routes/routes.json";
import { getLink } from "../../Redux/slices/user";
import { Link } from "react-router-dom";

const Links = () => {
  const user = useSelector((state) => state.Auth.user);
  const links = useSelector((state) => state.User.link);
  const el = useSelector((state) => state.User)

  console.log("links userrrrr", el);
  const [allData, setAllData] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLink(user.username));
    let my_links = links && links.filter((link) => link["status"] === "bind");
    console.log("mLinks fisrt : ", my_links);
    fetchPuzzleOfUser(my_links);
    fetchAllPost();
  }, []);

  const fetchPuzzleOfUser = () => {
    ApiService.fetchApiData(
      "/user/" + user.username + "/activity",
      "GET",
      "user"
    ).then((result) => {
      processLinks(result);
    });
  };

  const fetchAllPost = () => {
    const routePost = `${routes.routes.back_end.iconic_user.get_user.url}`;
    const method = `${routes.routes.back_end.iconic_user.get_user.method}`;

    let data = [];

    ApiService.fetchApiData(
      routePost + "/" + user.username + "/post",
      method,
      "user"
    ).then((result) => {
      console.log("result fetchAllPost  : ", result);
      setAllData(result);
      console.log("data after assignation : ", data);
      console.log("data puzzle to send  : ", data);
    });
  };

  const processLinks = (allData) => {
    allData  && allData.sort(function (a, b) {
      return a.date - b.date;
    });
  };

  return (
    <div className="links flex flex-fd-c flex-js-fs">
      <div className="page_title">
        Links
        <div className="register_header_path flex flex-js-fs">
          <Link to={"/home"}>Home</Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <span>Links</span>
        </div>
      </div>

      <div className="links_body flex">
        <div className="links_body_left flex flex-fd-c flex-js-fs">
          <User currentLinkedUserInfo={allData} link={links && links.filter((link) => link["status"] === "bind")} />
          <div className="links_body_left_contacts flex flex-fd-c flex-js-fs flex-ai-c">
            <LinkRequests
              request_link={links && links.filter(
                (link) => link["status"] === "pending"
              )}
            />
            <MyLinks
              my_links={links && links.filter((link) => link["status"] === "bind")}
            />
          </div>
        </div>
        <div className="links_body_right flex flex-fd-c flex-js-fs">
          {allData && (
            <Post
              post=""
              type="purchase"
              my_links={links && links.filter((link) => link["status"] === "bind")}
              currentLinkedUserInfo={allData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Links;
