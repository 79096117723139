/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import avatar from "../../Assets/vectors/avatar_user.svg";
import likes from "../../Assets/vectors/likes.svg";
import comments from "../../Assets/vectors/comments.svg";
import add_comment from "../../Assets/vectors/details/add_comment.svg";
import close_comment from "../../Assets/vectors/details/close_comment.svg";
import Enter_comment from "../../Assets/vectors/details/Enter_comment.svg";
import { useSelector, useDispatch } from "react-redux";
import CircleProgressBar from "../CircleProgressBar";
import CombinedDonutChart from "../DonutChart";
import percent_sold_color from "../../Assets/vectors/details/percent_sold_color.svg";
import up from "../../Assets/vectors/details/up.svg";
import down from "../../Assets/vectors/details/down.svg";
import purchase_icon from "../../Assets/vectors/details/purchase_icon.svg";
import percent_user_color from "../../Assets/vectors/details/percent_user_color.svg";
import user_percent_icon from "../../Assets/vectors/details/user_percent_icon.svg";
import like_red from "../../Assets/vectors/details/like_red.svg";
import routes from "../../Routes/routes.json";
import ApiService from "../../Utils/ApiService";
import { getUser } from "../../Redux/slices/user";
import "./details.scss";
import "./purchase-label.scss";

import { Link, useLocation, Navigate } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

const Details = () => {
  const user = useSelector((state) => state.Auth.user);
  const islightMode = useSelector((state) => state.darkMode);
  const location = useLocation();
  const [puzzle, setPuzzle] = useState(location.state);
  const [comment, setComment] = useState([]);

  useEffect(() => {
    // setPuzzle(location?.state || { like: "" });
    handleListComment();
  }, []);
  const [progressBar, setProgressBar] = useState(0);
  const [progressBarUser, setProgressBarUser] = useState(0);
  const [inputComment, setInputComment] = useState("");
  const [pricePuzzle, setPricePuzzle] = useState(0);
  console.log("puzzle location : ", puzzle);
  const [isShowingAllComments, setIsShowingAllComments] = useState(false);
  const [isHidingAllComments, setIsHidingAllComments] = useState(false);
  const dispatch = useDispatch();
  const [hasUserPartOfPuzzle, setHasUserPartOfPuzzle] = useState(false);
  const [isCommentActive, setIsCommentActive] = useState(false);
  const [isUserLike, setIsUserLike] = useState(
    puzzle?.likes?.includes(user.username) || ""
  );
  const [, setAllUsersPuzzle] = useState([]);
  const [dataDonuts, setDataDonuts] = useState([]);
  const [userPiece, setUserPiece] = useState(0);
  const [carrousel, setCarrousel] = useState(null);
  const [currentCarrousel, setCurrentCarrousel] = useState(null);
  const [betweenDateValue, setBetweenDateValue] = useState(0);

  const [selectedSegment, setSelectedSegment] = useState(null);

  const handleSegmentClick = (index) => {
    setSelectedSegment(index); // Select the clicked segment
  };

  const [activity] = useState([
    new Map([
      ["avatar", up],
      ["status", 1],
      ["name", "Pricing"],
      ["bought", "The price of the part has increased by 1%"],
    ]),
    new Map([
      ["avatar", down],
      ["status", 2],
      ["name", "Pricing"],
      ["bought", "The price of the part has increased by 1%"],
    ]),
    new Map([
      ["avatar", purchase_icon],
      ["status", 1],
      ["name", "Pricing"],
      ["bought", "The price of the part has increased by 1%"],
    ]),
    new Map([
      ["avatar", up],
      ["status", 1],
      ["name", "Pricing"],
      ["bought", "The price of the part has increased by 1%"],
    ]),
    new Map([
      ["avatar", purchase_icon],
      ["status", 1],
      ["name", "Pricing"],
      ["bought", "The price of the part has increased by 1%"],
    ]),
  ]);

  useEffect(() => {
    setCarrousel(puzzle?.images || "");
    setCurrentCarrousel(puzzle?.images[0] || "");
    dispatch(getUser(user.username));
    let percent =
      ((puzzle.total_piece - puzzle.available_piece) * 100) /
        puzzle?.total_piece ;
    setProgressBar(percent.toFixed(2));

    const detail_price_route = `${routes.routes.back_end.user.details_price.url}`;
    const detail_price_method = `${routes.routes.back_end.user.details_price.method}`;
    ApiService.fetchApiData(
      detail_price_route + "/" + puzzle?.reference + "/" + puzzle?.currency_code ||
        "" + "/" + puzzle?.currency_code ||
        "",
      detail_price_method,
      "transactions"
    ).then((result) => {
      setPricePuzzle(result.mid_price);
      console.log("MID PRICE", result.mid_price);
    });

    const puzzlesRoute = `${routes.routes.back_end.puzzle.gallery2.url}`;
    const method = `${routes.routes.back_end.puzzle.gallery2.method}`;
    const userIdRoute = `${routes.routes.back_end.iconic_user.get_user_id.url}`;
    const userIdMethod = `${routes.routes.back_end.iconic_user.get_user_id.method}`;
    const colors = ["#00E396", "#FF4560", "#FEB019", "#775DD0", "#008FFB", "#FFFFFF", "#FEB019", "#FF4560", "#00E396", "#008FFB", "#775DD0", "#546E7A", "#26A69A", "#D10CE8"]; 

    ApiService.fetchApiData(
      puzzlesRoute + puzzle?.reference + "/puzzle",
      method,
      "puzzle"
    ).then((result) => {
      // First API call to get puzzle data
      let dataFilter = result.filter((value) => value.percent >= 10);
      console.log("dataFilter:", dataFilter);

      // Fetch user data for each item in dataFilter
      Promise.all(
        dataFilter.map((value) =>
          ApiService.fetchApiData(
            userIdRoute +'/'+ value.user_id,
            userIdMethod,
            "user"
          )
        )
      ).then((userResults) => {
        let tmp = [];
        let reste = 0;
        dataFilter.forEach((value, index) => {
          let user = userResults[index]; // Corresponding user data
          let obj = {
            value: parseInt(value.percent),
            label: user.username, // Assigning username to label
            color: colors[index % colors.length],
          };
          tmp.push(obj);
          reste = reste + parseInt(value.percent);
        });
        if(100 - reste > 0) {
          let obj1 = {
            value: 100 - reste,
            label: "Other user",
            color: "#FDF1B8"
          };
          tmp.push(obj1);
        }
        setDataDonuts(tmp);
        setAllUsersPuzzle(dataFilter);
        checkPercent(dataFilter);
      });
    });
  }, []);

  const checkPercent = (result) => {
    result.map((value, key) => {
      if (value.user_id === user.id) {
        setHasUserPartOfPuzzle(true);
        setProgressBarUser(((value.piece * 100)/puzzle.total_piece).toFixed(2));
        setUserPiece(value.piece);
      }
    });
  };

  const handleAddComment = () => {
    let data = {};
    data.user = user.username;
    data.message = inputComment;
    const addCommentsRoute = `${routes.routes.back_end.puzzle.add_comment.url}`;
    const method = `${routes.routes.back_end.puzzle.add_comment.method}`;

    ApiService.sendingPutData(
      addCommentsRoute + puzzle.reference,
      method,
      data,
      "puzzle"
    ).then((result) => {
      //console.log("add comment : ", result);
      setPuzzle(result);
      setIsCommentActive(false);
    });
  };

  const handleListComment = () => {
    const listCommentsRoute = `${routes.routes.back_end.puzzle.list_comment.url}`;
    const method = `${routes.routes.back_end.puzzle.list_comment.get_method}`;

    ApiService.fetchApiData(
      listCommentsRoute + puzzle.reference,
      method,
      "puzzle"
    ).then((result) => {
      setComment(result);
    });
  };

  const handleLike = () => {
    let likeOrDislike = "";
    puzzle.likes.includes(user.username)
      ? (likeOrDislike = "dislike")
      : (likeOrDislike = "like");
    //console.log("likeOrDislike : ", likeOrDislike);
    let data = { username: user.username };
    const puzzleRoute = `${routes.routes.back_end.puzzle.puzzle.url}`;
    const method = `${routes.routes.back_end.puzzle.puzzle.method_like}`;
    ApiService.sendingPutData(
      puzzleRoute + puzzle.reference + "/" + likeOrDislike,
      method,
      data,
      "puzzle"
    ).then((result) => {
      //console.log("after like or dislike : ", result);
      setPuzzle(result);
      setIsUserLike(result.likes.includes(user.username));
    });
  };

  const renderImgLike = (img) => {
    return (
      <img
        draggable="false"
        src={img}
        alt=""
        className="details_container_stats_social_like_meta"
        onClick={() => handleLike()}
      />
    );
  };

  const handleCarrousel = (value) => {
    setCurrentCarrousel(value);
  };

  //SEE ALL COMMENTS
  const handleSeeMoreComments = (event) => {
    event.preventDefault();

    if (isShowingAllComments) {
      setIsShowingAllComments(false);
      setIsHidingAllComments(true);
    } else {
      setIsShowingAllComments(true);
      setIsHidingAllComments(false);
    }
  };

  console.log("date aujourdhui", moment().format("DD:MM:YYYY"));
  console.log("date end", moment(puzzle?.date_end).format("DD:MM:YYYY"));

  const dateNow = moment().format("DD:MM:YYYY");
  const dateEnd = moment(puzzle?.date_end).format("DD:MM:YYYY");

  const betweenDate = moment(dateEnd, "DD:MM:YYYY").diff(
    moment(dateNow, "DD:MM:YYYY"),
    "days"
  );

const start_date = moment(puzzle?.date_start, 'YYYY-MM-DD');
const end_date = moment(puzzle?.date_end, 'YYYY-MM-DD');
const today = moment();

  const isNotStarted = today.isAfter(start_date)
  const isFinished = (today.isBefore(start_date) && today.isBefore(end_date)) ||  betweenDate < 0

  console.log("Différence en jours :", betweenDate);

  useEffect(() => {
    setBetweenDateValue(betweenDate);
  }, [puzzle]);

  return (
    <>
      {puzzle === null ? (
        <>{<Navigate to="/" replace />}</>
      ) : (
        <>
          <div className="details">
            <div className="details_header flex flex-js-fs flex-ai-c">
              <div className="">{puzzle.title}</div>
              {user && progressBarUser > 0 && (
                <div className="details_header_userpercent flex flex-ai-c">
                  <img src={user_percent_icon} alt="" />
                  {progressBarUser}%
                </div>
              )}
            </div>
            <div className="register_header_path flex flex-js-fs path-margin">
              Home <div className="register_header_path_gt">{">"}</div>{" "}
              <span>PUZZLES</span>{" "}
              <div className="register_header_path_gt">{">"}</div>{" "}
              <span>{puzzle.title}</span>
            </div>
            <div className="details_container flex">
              <div className="details_container_puzzle">
                <div className="details_container_image">
                  {carrousel && currentCarrousel && (
                    <img
                      draggable="false"
                      src={currentCarrousel}
                      alt=""
                      className="details_container_image_meta"
                    />
                  )}

                  <div className="details_container_image_carousel">
                    {carrousel &&
                      carrousel.map((value) => {
                        if (value == currentCarrousel) {
                          return (
                            <img
                              draggable="false"
                              src={value}
                              alt=""
                              id="active"
                              className="details_container_image_carousel_meta"
                              onClick={() => handleCarrousel(value)}
                            />
                          );
                        }
                        return (
                          <img
                            draggable="false"
                            src={value}
                            alt=""
                            //id="active"
                            className="details_container_image_carousel_meta"
                            onClick={() => handleCarrousel(value)}
                          />
                        );
                      })}
                  </div>
                </div>
                <div
                  className={`details_container_stats flex  ${
                    islightMode == false
                      ? "background_item_light"
                      : "background_item_dark"
                  }`}
                >
                  <div className="details_container_stats_left flex flex-fd-c flex-js-sb">
                    <div className="details_container_stats_content">
                      <div className="details_container_stats_content_category">
                        {puzzle.class}
                      </div>
                      <div
                        className={`details_container_stats_content_title ${
                          islightMode == false
                            ? "color-black "
                            : "color-text-header"
                        }`}
                      >
                        {puzzle.title}
                      </div>
                      <div
                        className={`details_container_stats_content_description ${
                          islightMode == false
                            ? "color_gray"
                            : "color-text-header"
                        }`}
                      >
                        {puzzle.description}
                      </div>
                    </div>
                    <div className="details_container_stats_bottom">
                      <div className="details_container_stats_author">
                        <div className="details_container_stats_author_avatar">
                          <img
                            draggable="false"
                            src={avatar}
                            alt=""
                            id="status_1"
                            className="details_container_stats_author_avatar_meta"
                          />
                        </div>
                        <div className="details_container_stats_author_info">
                          <div className="details_container_stats_author_title">
                            Created by
                          </div>
                          <div className="details_container_stats_author_name">
                            Thomas Hope
                          </div>
                        </div>
                      </div>
                      <div className="details_container_stats_social">
                        <div className="details_container_stats_social_like flex">
                          {puzzle?.likes?.includes(user.username)
                            ? renderImgLike(like_red)
                            : renderImgLike(likes)}
                          <span>{puzzle?.likes?.length}</span>
                        </div>
                        <div
                          className="details_container_stats_social_comment flex"
                          onClick={() => setIsCommentActive(true)}
                        >
                          <img
                            draggable="false"
                            src={comments}
                            alt=""
                            className="details_container_stats_social_comment_meta"
                          />
                          <span>{puzzle?.comments?.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details_container_stats_line"></div>
                  <div className="details_container_stats_right ">
                    <div className="details_container_stats_price">
                      <div div className="details_container_stats_price_amount">
                        {/*<div className="details_container_stats_price_amount_title">
                              Pricing
                            </div>*/}
                        <div
                          className={`flex flex-fd-c details_container_stats_price_amount_piece ${
                            islightMode == false ? "color_purple" : ""
                          }`}
                        >
                          <div>
                            {(
                              puzzle.initial_price / puzzle.total_piece
                            ).toFixed(2)}
                            <span>$</span>
                          </div>
                          <span>per/piece</span>
                        </div>
                      </div>
                      <div className="details_container_stats_price_owned price-owned">
                        <div className="label-puzzle">
                          {puzzle.label ? puzzle.label : "First owned"}
                        </div>
                      </div>
                      <div className="details_container_stats_price_infos">
                        <div className="details_container_stats_price_stock">
                          <div className="details_container_stats_price_stock_title">
                            Total piece
                          </div>
                          <div className="details_container_stats_price_stock_amount">
                            {puzzle.total_piece}
                          </div>
                        </div>
                        <hr></hr>

                        <div className="details_container_stats_price_value">
                          <div className="details_container_stats_price_available_title">
                            Total value
                          </div>
                          <div className="details_container_stats_price_available_amount">
                            $
                            {puzzle.total_piece != 0 ? 
                            (
                              (puzzle.initial_price / puzzle.total_piece) *
                              puzzle.total_piece
                            ).toFixed(2) : 0}
                          </div>
                        </div>
                      </div>
                      {!hasUserPartOfPuzzle ? (
                        <Link
                          to="/purchase"
                          state={{
                            puzzle: puzzle,
                            userPiece: userPiece,
                            percentUser: progressBarUser,
                            isBuy: true,
                          }}
                        >
                          <div className="details_container_stats_price_button flex">
                            <span>Buy puzzles {">"}</span>
                          </div>
                        </Link>
                      ) : (
                        <div className="flex details_container_stats_price_all-button">
                          {puzzle.market === "secondary" && (
                            <Link
                              to="/purchase"
                              state={{
                                puzzle: puzzle,
                                userPiece: userPiece,
                                percentUser: progressBarUser,
                                isBuy: false,
                              }}
                            >
                              {console.log("puzzle -- sell : ", puzzle)}
                              <div className="details_container_stats_price_all-button_sell flex">
                                <span className="details_container_stats_price_all-button_text">
                                  SELL{" "}
                                </span>
                              </div>
                            </Link>
                          )}
                          {puzzle.market === "secondary" ? (
                            <Link
                              to="/purchase"
                              state={{
                                puzzle: puzzle,
                                userPiece: userPiece,
                                percentUser: progressBarUser,
                                isBuy: true,
                              }}
                            >
                              <div className="details_container_stats_price_all-button_buy flex">
                                <span className="details_container_stats_price_all-button_text">
                                  BUY
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <Link
                              to="/purchase"
                              state={{
                                puzzle: puzzle,
                                userPiece: userPiece,
                                percentUser: progressBarUser,
                                isBuy: true,
                              }}
                            >
                              <div className="details_container_stats_price_button details_container_stats_price_buy flex">
                                <span>Buy puzzles {">"}</span>
                              </div>
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="details_container_point_carousel">
              {carrousel &&
                carrousel.map((value) => {
                  if (value == currentCarrousel) {
                    return (
                      <>
                        <div
                          className="details_container_point_carousel_3"
                          onClick={() => handleCarrousel(value)}
                        >
                          .
                        </div>
                      </>
                    );
                  }
                  return (
                    <>
                      <div
                        className="details_container_point_carousel_1"
                        onClick={() => handleCarrousel(value)}
                      >
                        .
                      </div>
                    </>
                  );
                })}
            </div>
            <div
              className={`details_title flex ${
                islightMode == false ? "" : "color_white"
              }`}
            >
              More about this puzzle
            </div>
            <div className="details_section flex">
              <div
                className={`details_section_description ${
                  islightMode == false
                    ? "background_item_light"
                    : "background_item_dark"
                }`}
              >
                <div className="details_section_description_title">
                  Stats puzzle
                </div>
                {puzzle.market === "secondary" && (
                  <>
                    { dataDonuts.length > 0 &&  (
                        <CombinedDonutChart
                        className="donut-chart"
                        trailStrokeColor="#d3d3d3"
                        dataDonut={dataDonuts}
                        radius={100}
                        strokeWidth={30}
                        size={300}
                        selectedSegment={selectedSegment} 
                        onSegmentClick={handleSegmentClick} 
                        />
                    )};
                    {dataDonuts.map((item, index) => (
                       <div
                       key={item.value}
                       className="flex align-items-center flex-ai-c details_section_description_percent"
                       onClick={() => handleSegmentClick(index)} // Handle click from the div
                       style={{
                        cursor: "pointer",
                        fontWeight: selectedSegment === index ? "bold" : "normal", // Bold if selected
                        opacity: selectedSegment === index ? 1 : 0.7, // Make selected more visible
                        backgroundColor: selectedSegment === index ? 'rgba(0, 0, 0, 0.1)' : 'transparent', // Add transparent background when selected
                        borderRadius: '8px', // Optional: round the corners
                        padding: '8px', // Optional: add padding for better spacing
                        transition: 'background-color 0.3s ease', // Smooth transition for background color change
                      }}
                    >
                          <div className="flex w-100 color-percent">
                            <svg width="12" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" width="10" height="10" rx="2" fill={item.color} />
                            </svg>
                            <div className="flex details_section_description_percent_sold">
                              <span>
                                {item.value}% &nbsp;
                                @{user.username === item.label ? "Your part" : item.label}
                              </span>
                            </div>
                          </div>
                        </div>
                    ))}
                  </>
                )};
                {puzzle.market === "primary" && (
                  <>
                    {user && progressBarUser > 0 && (
                      <CircleProgressBar
                        trailStrokeColor=""
                        strokeColor="#3EFCF0"
                        percentage={progressBarUser}
                        innerText="complete"
                        radioCircle1={15.91549430918954}
                        radioCircle2={15.91549430918954}
                        y="14%"
                        size={80}
                        x={38}
                        className="circle_user"
                        textClass="circle-percentage-user"
                        withtext={true}
                      />
                    )}
                    <CircleProgressBar
                      trailStrokeColor="#212332"
                      strokeColor="#9B8EFF"
                      percentage={progressBar}
                      innerText="complete"
                      radioCircle1={15.91549430918954}
                      radioCircle2={15.91549430918954}
                      y="24%"
                      size={80}
                      x={38}
                      className="circle_container"
                      textClass="circle-percentage"
                      withtext={true}
                    />

                    <div className="flex flex-ai-c details_section_description_percent">
                      <img src={percent_sold_color} alt=""></img>
                      <div className="details_section_description_percent_sold">
                        % pieces sold{" "}
                      </div>
                      {user && progressBarUser > 0 && (
                        <>
                          <img src={percent_user_color} alt=""></img>
                          <div className="">Your part </div>
                        </>
                      )}
                    </div>
                    <div className="details_section_description_content">
                      <div className="details_section_description_content_title">
                        Pieces
                      </div>
                      <div className="flex details_section_description_info flex-js-sb">
                        <div className="details_section_description_info_text">
                          Sold{" "}
                          {user && progressBarUser > 0 && (
                            <span>(Your pieces)</span>
                          )}
                        </div>
                        <div className="details_section_description_info_number">
                          {puzzle.total_piece - puzzle.available_piece}
                          {user && progressBarUser > 0 && (
                            <span>({userPiece})</span>
                          )}
                          /{puzzle.total_piece}
                        </div>
                      </div>
                      <div className="flex details_section_description_info flex-js-sb">
                        <div className="details_section_description_info_text">
                          Remaining
                        </div>
                        <div className="details_section_description_info_number">
                          {puzzle.available_piece}
                        </div>
                      </div>
                      <div className="details_section_description_content_title">
                        Value
                      </div>
                      <div className="flex details_section_description_info flex-js-sb">
                        <div className="details_section_description_info_text">
                          Locked
                        </div>
                        <div className="details_section_description_info_number">
                          $
                          {((puzzle.total_piece - puzzle.available_piece) *
                            puzzle.initial_price) /
                            puzzle.total_piece}
                          /$
                          {puzzle.total_piece *
                            (puzzle.initial_price / puzzle.total_piece)}
                        </div>
                      </div>
                      <div className="flex details_section_description_info flex-js-sb">
                        <div className="details_section_description_info_text">
                          Remaining
                        </div>
                        <div className="details_section_description_info_number">
                          $
                          {puzzle.available_piece *
                            (puzzle.initial_price / puzzle.total_piece)}
                        </div>
                      </div>
                      <div className="between">
                        {isNotStarted ? (
                          <>
                           <span className="time-remain">Start Date</span>
                            <span className="time-remain value">{moment(puzzle?.date_start).format("YYYY/MM/DD")}</span>
                          </>
                        ) : isFinished ? (
                          <>
                            <span className="time-remain">Time Remaining</span>
                            <span className="time-remain value">-</span>
                          </>
                        ) : (
                          <>
                            <span className="time-remain">Time Remaining</span>
                            <span className="time-remain value">
                              {betweenDate} Days
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div
                className={`details_section_activity ${
                  islightMode == false
                    ? "background_item_light"
                    : "background_item_dark"
                }`}
              >
                <div className="details_section_activity_title">
                  LAST ACTIVITY
                </div>
                {activity.map((value, key) => {
                  return (
                    <div className="details_section_activity_content">
                      <div className="details_section_activity_content_row">
                        <div className="details_section_activity_content_row_avatar">
                          <img
                            draggable="false"
                            src={value.get("avatar")}
                            alt=""
                            //id={["status_" + value.get("status")]}
                            className="details_section_activity_content_row_avatar_meta"
                          />
                        </div>
                        <div className="details_section_activity_content_row_author">
                          <div
                            className={`details_section_activity_content_row_name ${
                              islightMode == false ? "color_blue_details" : ""
                            }`}
                          >
                            {value.get("name")}
                            <span className="details_section_activity_content_row_name_text">
                              Today
                            </span>
                          </div>
                          <div className="details_section_activity_content_row_info">
                            <span>{value.get("bought")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="details_section_activity_link flex">
                  <a href="">See more activity</a>
                </div>
              </div>
              <div
                className={`details_section_comments ${
                  islightMode == false
                    ? "background_item_light"
                    : "background_item_dark"
                }`}
              >
                <div className="details_section_comments_title">
                  COMMENTS
                  <span className="details_section_comments_title_length">
                    {comment?.length}
                  </span>
                  {!isCommentActive ? (
                    <img
                      src={add_comment}
                      className="flex-js-fe"
                      onClick={() => setIsCommentActive(true)}
                    />
                  ) : (
                    <img
                      src={close_comment}
                      className="flex-js-fe"
                      onClick={() => setIsCommentActive(false)}
                    />
                  )}
                </div>
                {/******* * */}
                {isCommentActive && (
                  <div className="details_section_comments_content">
                    <div className="details_section_comments_content_row flex">
                      <div className="details_section_comments_content_row_avatar">
                        <img
                          draggable="false"
                          src={avatar}
                          alt=""
                          //id={["status_" + comment[1].get("status")]}
                          className="details_section_comments_content_row_avatar_meta"
                        />
                      </div>
                      <div className="details_section_comments_content_row_right flex flex-js-fs">
                        <input
                          type="text"
                          className="details_section_comments_content_row_right_input"
                          placeholder="Type your comment"
                          onChange={(e) => setInputComment(e.target.value)}
                        />

                        {/*<div className="details_section_comments_content_row_right_input" contentEditable="true" data-text="type your comment"></div>*/}
                        <img
                          src={Enter_comment}
                          alt=""
                          className="details_section_comments_content_row_right_enter"
                          onClick={() => handleAddComment()}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="comments">
                  {comment
                    .slice(0)
                    .reverse()
                    .map((value, key) => {
                      if (isShowingAllComments) {
                        return (
                          <div
                            className="details_section_comments_content "
                            key={key}
                          >
                            <div className="details_section_comments_content_row">
                              <div className="details_section_comments_content_row_avatar">
                                <img
                                  draggable="false"
                                  src={avatar}
                                  alt=""
                                  id="status_all"
                                  className="details_section_comments_content_row_avatar_meta"
                                />
                              </div>
                              <div className="details_section_activity_content_row_author">
                                <div
                                  className={`details_section_comments_content_row_name ${
                                    islightMode === false
                                      ? "color_blue_details"
                                      : ""
                                  }`}
                                >
                                  @{value.user}
                                  <span className="details_section_comments_content_row_date">
                                    {value.date_create}
                                  </span>
                                </div>
                                <div className="details_section_comments_content_row_info">
                                  {value.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }

                      if (key < 4) {
                        return (
                          <div
                            className="details_section_comments_content"
                            key={key}
                          >
                            <div className="details_section_comments_content_row">
                              <div className="details_section_comments_content_row_avatar">
                                <img
                                  draggable="false"
                                  src={avatar}
                                  alt=""
                                  id="status_all"
                                  className="details_section_comments_content_row_avatar_meta"
                                />
                              </div>
                              <div className="details_section_activity_content_row_author">
                                <div
                                  className={`details_section_comments_content_row_name ${
                                    islightMode === false
                                      ? "color_blue_details"
                                      : ""
                                  }`}
                                >
                                  @{value.user}
                                  <span className="details_section_comments_content_row_date">
                                    {value.date_create}
                                  </span>
                                </div>
                                <div className="details_section_comments_content_row_info">
                                  {value.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }

                      return null;
                    })}

                  <div className="details_section_comments_link flex">
                    <div className="details_section_comments_link flex">
                      {!isShowingAllComments && (
                        <a href="" onClick={handleSeeMoreComments}>
                          See more comments
                        </a>
                      )}
                      {isShowingAllComments && !isHidingAllComments && (
                        <a href="" onClick={handleSeeMoreComments}>
                          Hide all comments
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {console.log("dataDonuts : ", dataDonuts)}
        </>
      )}
    </>
  );
};
export default Details;
