import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import Gallery from "./Gallery";
import { useSelector, useDispatch } from "react-redux";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getUser } from "../../../Redux/slices/user";
import badge from "../../../Assets/vectors/Profile/unverified_profile_badge.svg";

const Profile = () => {
  const user = useSelector((state) => state.Auth.user);
  const linksUser = useSelector((state) => state.User.link);
  const [allClasses, setAllClasses] = useState([]);
  const [allPuzzles, setAllPuzzles] = useState([]);
  const [connected, setConnected] = useState(false);
  const [profilVisited, setProfilVisited] = useState(null);
  const [userconnected, setUserconnected] = useState(false);
  let { username } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [userVisited, setUserVisited] = useState(location.state);
  const navigate = useNavigate();

  useEffect(() => {
    const classesRoute = `${routes.routes.back_end.puzzle.classes.url}`;
    const method = `${routes.routes.back_end.puzzle.classes.method}`;

    ApiService.fetchApiData(classesRoute, method, "puzzle").then((result) => {
      setAllClasses(result);
    });
    fetchUser();
    fetchPuzzles();
  }, [username, userVisited]);

  /*if (profilVisited && profilVisited.visibility == "private") {
    return;
    <>{navigate("/home")}</>;
  }*/
    const fetchPuzzles = () => {
      const puzzlesRoute = `${routes.routes.back_end.puzzle.gallery.url}`;
      const method = `${routes.routes.back_end.puzzle.puzzles.method}`;

      //dispatch(getUser(username));

      ApiService.fetchApiData(
        puzzlesRoute + userVisited.id + "/user",
        method,
        "puzzle"
      ).then((result) => {
        let dataFilter = result.filter((value) => value.state === "validated");
        setAllPuzzles(dataFilter);
      });
    }
    const fetchUser = () => {
      if (!userVisited) return;
  
      const routeGetUser = `${routes.routes.back_end.iconic_user.get_user.url}`;
      const methodGetUser = `${routes.routes.back_end.iconic_user.get_user.method}`;
  
      ApiService.fetchApiData(
        routeGetUser + "/id/" + userVisited.id,
        methodGetUser,
        "user"
      )
        .then((result) => {
          console.log('User fetched successfully:', result);
          setProfilVisited(result);
        })
        .catch((err) => {
          console.error("Error fetching user:", err.message);
        });
    };
  if (
    profilVisited &&
    profilVisited.privacy["visit_my_profile"] == "my_links"
  ) {
    return (
      <div className="profile">
        {allClasses && allPuzzles && profilVisited ? (
          <>
            <Dashboard user={profilVisited} userconnected={userconnected} />
            {linksUser.some(
              (link) =>
                link["status"] === "bind" &&
                link["username"] == profilVisited.username
            ) ? (
              <>
                {allClasses && allPuzzles && (
                  <Gallery
                    allClasses={allClasses}
                    allPuzzles={allPuzzles}
                    isMyProfile={false}
                    userActive={profilVisited}
                  />
                )}
              </>
            ) : (
              <>
                
              </>
            )}
          </>
        ) : (
          <p></p>
        )}
      </div>
    );
  }

  return (
    <div className="profile">
      {console.log("profile visited : ", profilVisited)}
      {allClasses && allPuzzles && profilVisited ? (
        <>
          <Dashboard user={profilVisited} userconnected={userconnected} />
          {console.log("allClasses   : ", allClasses)}
          {console.log("allClasses   : ", allPuzzles)}
          {allClasses && allPuzzles && (
            <Gallery
              allClasses={allClasses}
              allPuzzles={allPuzzles}
              isMyProfile={false}
              userActive={profilVisited}
            />
          )}
        </>
      ) : (
        <p></p>
      )}
      {console.log("profil visited : ", profilVisited)}
    </div>
  );
};

export default Profile;
