import React, { useState } from "react";
import { useSelector } from "react-redux";

const CombinedDonutChart = ({
  className,
  trailStrokeColor,
  dataDonut,
  radius = 45,
  strokeWidth = 10,
  size = 150,
  selectedSegment,
  onSegmentClick
}) => {
  const circumference = 2 * Math.PI * radius; // Full circumference of the circle
  const center = size / 2; // Center of the circle
  const user = useSelector((state) => state.Auth.user);

  let cumulativeOffset = 0; // To keep track of where each segment starts

  return (
    <figure className={className} style={{ width: size, height: size }}>
      <svg width={size} height={size}>
        {/* Render the trail (background circle) */}
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="transparent"
          stroke={trailStrokeColor}
          strokeWidth={strokeWidth}
        />

        {/* Render each segment of the donut */}
        {dataDonut.map((item, index) => {
          const segmentLength = (item.value / 100) * circumference; // Length of the current segment

          // Set correct offset for the first segment
          const offset = index === 0 ? 0 : cumulativeOffset;

          // Update cumulative offset for next segment
          cumulativeOffset += segmentLength;

          // Check if the current segment is selected
          const isSelected = selectedSegment === index;

          return (
            <circle
              key={index}
              cx={center}
              cy={center}
              r={radius}
              fill="transparent"
              stroke={item.color} // Color from dataDonut
              strokeWidth={isSelected ? strokeWidth + 5 : strokeWidth} // Increase strokeWidth if selected
              strokeDasharray={`${segmentLength} ${circumference - segmentLength}`} // Define segment size
              strokeDashoffset={-offset} // Position the segment
              strokeLinecap="butt"
              style={{ cursor: "pointer", transition: "stroke-width 0.3s ease-in-out" }}
              onClick={() => onSegmentClick(index)} // Handle click to focus
            />
          );
        })}

        {/* Display selected segment's label and value inside the chart */}
        {selectedSegment !== null && (
          <g className="donut-label" style={{ textAnchor: "middle" }}>
            <text x="50%" y="50%" className="donut-value-text">
              {dataDonut[selectedSegment].value}%
            </text>
            <text x="50%" y="55%" className="donut-label-text">
              { user.username === dataDonut[selectedSegment].label ? "Your part" : dataDonut[selectedSegment].label }
            </text>
          </g>
        )}
      </svg>
    </figure>
  );
};

export default CombinedDonutChart;
