import dot from "../../Assets/vectors/Profile/dot.svg";
import Link from "./Link";
import React from 'react';
const MyLinks = ({ my_links }) => {
  if (my_links && my_links.length != "0") {
    return (
      <div className="links_body_left_contacts_mylinks">
        <div className="links_body_left_contacts_requests_title flex-ai-c flex flex-js-fs ">
          <p>My links</p> <img src={dot} alt=""></img>{" "}
          <span> {my_links.length} </span>
        </div>
        {my_links.map((link, index) => {
        return (
          <div key={index} style={{ marginBottom: "50px" }}>
            <Link avatar={link.avatar} user={link} />
          </div>
        );
      })}
      </div>
    );
  } else {
    return (
      <div className="links_body_left_contacts_mylinks">
        <div className="links_body_left_contacts_requests_title flex-ai-c flex flex-js-fs ">
          No links
        </div>
      </div>
    );
  }
};

export default MyLinks;
