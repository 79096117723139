import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import Gallery from "./Gallery";
import { useSelector, useDispatch } from "react-redux";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useParams } from "react-router-dom";
import { getUser } from "../../../Redux/slices/user";

const Myprofile = () => {
  const user = useSelector((state) => state.Auth.user);
  const [allClasses, setAllClasses] = useState([]);
  const [allPuzzles, setAllPuzzles] = useState([]);
  const profil_visted = useSelector((state) => state.User);
  const [profilVisited, setProfilVisited] = useState(null);
  const [userconnected, setUserconnected] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const puzzlesRoute = `${routes.routes.back_end.puzzle.gallery.url}`;
      const classesRoute = `${routes.routes.back_end.puzzle.classes.url}`;
      const method = `${routes.routes.back_end.puzzle.puzzles.method}`;

      console.log("Fetching puzzles for user:", user.username);

      ApiService.fetchApiData(
        puzzlesRoute + user.id + "/user",
        method,
        "puzzle"
      ).then((result) => {
        let dataFilter = result.filter((value) => value.state === "validated");
        setAllPuzzles(dataFilter);
      }).catch((err) => {
        console.error("Failed to fetch puzzles:", err.message);
      });

      ApiService.fetchApiData(classesRoute, method, "puzzle").then((result) => {
        setAllClasses(result);
      }).catch((err) => {
        console.error("Failed to fetch classes:", err.message);
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchUser();
    }
  }, [user]);

  const fetchUser = () => {
    if (!user) return;

    const routeGetUser = `${routes.routes.back_end.iconic_user.get_user.url}`;
    const methodGetUser = `${routes.routes.back_end.iconic_user.get_user.method}`;

    ApiService.fetchApiData(
      routeGetUser + "/id/" + user.id,
      methodGetUser,
      "user"
    )
      .then((result) => {
        console.log('User fetched successfully:', result);
        setProfilVisited(result);
      })
      .catch((err) => {
        console.error("Error fetching user:", err.message);
      });
  };

  return (
    <div className="profile">
      {profilVisited ? (
        <Dashboard user={profilVisited} userconnected={userconnected} />
      ) : (
        <div>Loading profile...</div>
      )}
      {allClasses && allPuzzles && (
        <Gallery allClasses={allClasses} allPuzzles={allPuzzles} isMyProfile={true} userActive={user} />
      )}
    </div>
  );
};

export default Myprofile;
