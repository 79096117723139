/* eslint-disable no-mixed-operators */
/* eslint-disable no-new-object */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useCallback, useRef } from "react";
import Activity from "./Activity";
import { useSelector, useDispatch } from "react-redux";
import avatar from "../../../Assets/img/Profile/avatar_profile.png";
import verified from "../../../Assets/vectors/Profile/account_verified.svg";
import edit from "../../../Assets/vectors/Profile/edit_profile_icon.svg";
import save from "../../../Assets/vectors/Profile/save_profile.svg";
import add from "../../../Assets/vectors/Profile/add_social_network.svg";

import twitter from "../../../Assets/vectors/Profile/twitter.svg";
import facebook from "../../../Assets/vectors/Profile/facebook.svg";
import linkedin from "../../../Assets/vectors/Profile/linkedin.svg";
import other from "../../../Assets/vectors/Profile/other_social_network.svg";

import badge from "../../../Assets/vectors/Profile/unverified_profile_badge.svg";
import default_avatar from "../../../Assets/vectors/Profile/default_avatar.svg";
import add_link_btn from "../../../Assets/img/Profile/add_link_btn.svg";

import { requestLink } from "../../../Redux/slices/user";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { getUser } from "../../../Redux/slices/user";
import { updateUsers } from "../../../Redux/slices/user";
import btn_link_accept from "../../../Assets/vectors/Profile/btn_link_accept.svg";
import btn_link_refuse from "../../../Assets/vectors/Profile/btn_link_refuse.svg";
import { acceptLink, refuseLink, getLink } from "../../../Redux/slices/user";
import { AddLink } from "./AddLink";
import Modal from "../../common/Modal";
import UserItemLink from "./UserItemLink";
//import { slice } from "../../../Redux/slices/user";

const Dashboard = ({ user, userconnected }) => {
  const userli = user['link'];
  const otherUser = useSelector((state) => state.User);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const [profile, setProfile] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [inputDescription, setInputDescription] = useState(user.about_me);
  const [linkValue, setLinkValue] = useState();
  // const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(user.avatar || default_avatar); 
  const [, setPhotoUploadName] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setLinkValue({
      facebook: user.link_fb,
      linkedin: user.link_in,
      twitter: user.link_twitter,
    })
  }, [user]);

  function handleChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const uploadFileName = file.name;
      const uploadedPhotoURL = URL.createObjectURL(file);
      setFile(uploadedPhotoURL);
      setUploadedPhotoURL(file);
      setPhotoUploadName(uploadFileName);
    }
  }

  

  const handleAccept = () => {
    let data = new Object();
    const user_username = user.username;
    data.target = otherUser.username;

    dispatch(acceptLink(otherUser.username, user_username));
  };

  const handleRefuse = () => {
    let data = new Object();
    const user_username = user.username;
    data.target = otherUser.username;
    dispatch(refuseLink(otherUser.username, user_username));
  };


  const handleSave = async () => {
    try {
      setProfile(true);
  
      const routeUpdateUser = routes.routes.back_end.iconic_user.update_user.url;
      const methodUpdateUser = routes.routes.back_end.iconic_user.update_user.method;
      let data = {
        about_me: inputDescription,
        avatar: uploadedPhotoURL,
        link_fb: linkValue?.facebook,
        link_twitter: linkValue?.twitter,
        link_in: linkValue?.linkedin,
      };
  
      const validData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value != null && value !== '')
      );
  
      if (Object.keys(validData).length === 0) {
        throw new Error("No valid fields to update");
      }
  
      const result = await ApiService.postExpectFormData(
        `${routeUpdateUser}/${user.username}`,
        methodUpdateUser,
        validData,
        "user"
      );
  
      dispatch(updateUsers(result));
      setInputDescription(result.about_me || "");
      if (result.avatar) {
        setUploadedPhotoURL(result.avatar); 
      }
      dispatch(getUser(user.username));
  
    } catch (err) {
      console.error("Error while updating user:", err.message);
      setProfile(false);
    }
  };
  

  const handleUpdateAvatar = () => {
    // console.log("avatar clicked");
    // TODO  : upload file and display it in change
  };

  const handleAddLink = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLinkSave = (linkData) => {
    console.log("Received link data:", linkData);
    setLinkValue(linkData);
    setIsOpen(false);
  };

  const onClose = useCallback(() => {
    setIsBlockModal(false);
  }, []);

  const openModal = () => {
    setIsBlockModal(true);
  };

  const addLink = () => {
    return (
      <Modal
        isOpen={isBlockModal}
        title="Select user to send link request"
        onClose={onClose}
      >
        <div className="users-list">
          <UserItemLink />
        </div>
      </Modal>
    );
  }

  const renderButton = () => {
    if (user) {
      const linksUser = userli;
      if (
        linksUser?.length !== 0 &&
        userconnected == false &&
        linksUser.some((link) => link["username"] == user.username)
      ) {
        if (
          linksUser.some(
            (link) =>
              link["status"] === "pending" && link["username"] == user.username
          )
        ) {
          console.log("pending");
          return (
            <div className="profile_container_about_right_linkrequest flex flex-ai-c">
              <div className="profile_container_about_right_linkrequest_text">
                Link request
              </div>
              <img
                className="profile_container_about_right_linkrequest_accept"
                src={btn_link_accept}
                alt=""
                onClick={() => handleAccept()}
              ></img>
              <img
                className="profile_container_about_right_linkrequest_refuse"
                src={btn_link_refuse}
                alt=""
                onClick={() => handleRefuse()}
              ></img>
            </div>
          );
        } else if (
          linksUser.some(
            (link) =>
              link["status"] === "bind" && link["username"] == user.username
          )
        ) {
          console.log("bind");
          return (
            <div className="profile_container_about_right_edit linked">
              Linked
            </div>
          );
        } else if (
          linksUser.some(
            (link) =>
              link["status"] === "sending" && link["username"] == user.username
          )
        ) {
          console.log("sending");
          return (
            <div className="profile_container_about_right_edit linked">
              Link Sent
            </div>
          );
        }
      } else {
        return (
          <div>
              <div
              className="profile_container_about_right_edit"
              onClick=
                {openModal}
            >
            <img
              draggable="false"
              src={add_link_btn}
              className={"profile_container_about_right_add_link"}
              alt=""
            />
            </div>
            {addLink()}
          </div>
        );
      }
    }
  };


  const renderDefaultProfile = () => {
    return (
      <div className="profile_container inline">
        <div className="profile_container_about inline">
          <div className="profile_container_about_left">
            <div className="profile_container_about_left_avatar flex">
            {uploadedPhotoURL ? (
              <img
                draggable="false"
                src={uploadedPhotoURL}
                // alt="User avatar"
                className="profile_container_about_left_avatar_img"
              />
            ) : (
                <img
                  draggable="false"
                  src={default_avatar}
                  className="profile_container_about_left_avatar_img"
                />
              )}
              {user.verified == 1 && (
                <img
                  draggable="false"
                  src={verified}
                  className="profile_container_about_left_avatar_verified"
                />
              )}
            </div>
            <div className="profile_container_about_left_pseudo">
              @{user.username}
            </div>
          </div>
          <hr className="profile_container_about_hr inline"></hr>
          <div className="profile_container_about_right">
            <div className="profile_container_about_right_title">About me</div>
            {inputDescription ? (
              <div className="profile_container_about_right_description">
                {inputDescription}
              </div>
            ) : (
              <div className="profile_container_about_right_description">
                Tell us something about yourself. Press the edit button.
              </div>
            )}
            <div className="profile_container_about_right_social_title">
              Réseaux
            </div>
            <div className="profile_container_about_right_social inline">
              {user?.link_twitter && (
                <div className="profile_container_about_right_social_logo">
                  <a href={user.link_twitter}>
                    <img
                      draggable="false"
                      src={twitter}
                      className="profile_container_about_right_social_logo_img"
                    />
                  </a>
                </div>
              )}
              {user?.link_fb && (
                <div className="profile_container_about_right_social_logo">
                  <a href={user.link_fb}>
                    <img
                      draggable="false"
                      src={facebook}
                      className="profile_container_about_right_social_logo_img"
                    />
                  </a>
                </div>
              )}
              {user?.link_in && (
                <div className="profile_container_about_right_social_logo">
                  <a href={user.link_in}>
                    <img
                      draggable="false"
                      src={linkedin}
                      className="profile_container_about_right_social_logo_img"
                    />
                  </a>
                </div>
              )}

              {!user.link_twitter && !user.link_fb && !user.link_in && (
                <div className="profile_container_about_right_social_content">
                  Share your social media. Press the edit button.
                </div>
              )}
            </div>
              <div
                className="profile_container_about_right_edit"
                onClick={() => setProfile(false)}
              >
                <img
                  draggable="false"
                  src={edit}
                  className={"profile_container_about_right_edit"}
                  alt=""
                />
              </div>
              { renderButton() }
          </div>
        </div>
        <Activity />
      </div>
    );
  };

  const renderEditProfile = () => {
    return (
      <div className="profile_container inline">
        <div className="profile_container_about inline">
          <div className="profile_container_about_left">
            <div className="profile_container_about_left_avatar flex">
              {user.avatar ? (
                <>
                  {file == null ? (
                    <img
                      draggable="false"
                      src={user.avatar}
                      className="profile_container_about_left_avatar_img"
                    />
                  ) : (
                    <img
                      draggable="false"
                      src={file}
                      className="profile_container_about_left_avatar_img"
                    />
                  )}
                  <div className="avatar_backgroung">
                    <label for="file">
                      <div
                        className="avatar_hover"
                        onClick={() => fileInputRef.current.click()}
                      ></div>
                    </label>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChange}
                     className="avatar_input"
                    style={{ display: "none" }}
                  />
                </>
              ) : (
                <>
                  {file == null ? (
                    <img
                      draggable="false"
                      src={default_avatar}
                      className="profile_container_about_left_avatar_img"
                    />
                  ) : (
                    <img
                      draggable="false"
                      src={file}
                      className="profile_container_about_left_avatar_img"
                    />
                  )}
                  <div className="avatar_backgroung">
                    <label for="file">
                      <div
                        className="avatar_hover"
                        onClick={() => handleUpdateAvatar()}
                      ></div>
                    </label>
                  </div>
                  <input
                    id="file"
                    type="file"
                    onChange={handleChange}
                    className="avatar_input"
                  />
                </>
              )}
              {user.verified == 1 && (
                <img
                  draggable="false"
                  src={verified}
                  className="profile_container_about_left_avatar_verified"
                />
              )}
            </div>
            <div className="profile_container_about_left_pseudo">
              @{user.username}
            </div>
          </div>
          <hr className="profile_container_about_hr inline"></hr>
          <div className="profile_container_about_right">
            <div className="profile_container_about_right_title">About me</div>
            <textarea
              className="profile_container_about_right_textarea"
              placeholder="Type here"
              onChange={(e) => {
                setInputDescription(e.target.value.trim());
              }}
            >
              {user.about_me}
            </textarea>
            <div className="profile_container_about_right_social_title">
              Réseaux
            </div>
            
              <div className="profile_container_about_right_social inline">
                {linkValue?.twitter && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={linkValue?.twitter}>
                      <img
                        draggable="false"
                        src={twitter}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}
                {linkValue?.facebook && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={linkValue?.facebook}>
                      <img
                        draggable="false"
                        src={facebook}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}
                {linkValue?.linkedin && (
                  <div className="profile_container_about_right_social_logo">
                    <a href={linkValue?.linkedin}>
                      <img
                        draggable="false"
                        src={linkedin}
                        className="profile_container_about_right_social_logo_img"
                      />
                    </a>
                  </div>
                )}

                <div className="profile_container_about_right_social_add">
                  <img
                    draggable="false"
                    src={add}
                    className="profile_container_about_right_social_add_img"
                    onClick={handleAddLink}
                  />
                </div>
              </div>
  

            <Modal isOpen={isOpen} title="Add Link" onClose={handleAddLink}>
              <AddLink
                onClose={handleLinkSave}
                dataLink_fb={linkValue?.facebook}
                dataLink_in={linkValue?.linkedin}
                dataLink_twitter={linkValue?.twitter}
              />
            </Modal>
            <div
              className="profile_container_about_right_edit"
              onClick={() => handleSave()}
            >
              <img
                draggable="false"
                src={save}
                className="profile_container_about_right_edit_icon"
              />
            </div>
          </div>
        </div>
        <Activity />
      </div>
    );
  };

  return (
    <>
      {!userconnected && (
        <div className="profile_path">
          {user.verified == 0 && (
            <img
              draggable="false"
              src={badge}
              alt=""
              className="profile_path_img"
            />
          )}
          <div className="profile_path_current">Links</div>
          <div className="profile_path_info">
            HOME {">"} <span>Links</span>
            {">"} <span>@{user.username} profile</span>
          </div>
        </div>
      )}
      {userconnected && (
        <div className="profile_path">
          {user.verified == 0 && (
            <img
              draggable="false"
              src={badge}
              alt=""
              className="profile_path_img"
            />
          )}
          <div className="profile_path_current">My profile</div>
          <div className="profile_path_info">
            HOME {">"} <span>My profile</span>
          </div>
        </div>
      )}
      {profile ? renderDefaultProfile() : renderEditProfile()}
    </>
  );
};

export default Dashboard;
